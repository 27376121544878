export enum RoleSettings {
  assign_all_properties = "setting_assign_all_properties",
  receive_all_permissions = "setting_receive_all_permissions",
  unlisted = "setting_unlisted",
  available_in_filter = "setting_available_in_filter",
  available_for_assignment = "setting_available_for_assignment",
}

export type RoleSetting = keyof typeof RoleSettings;
export type RoleSettingKey = (typeof RoleSettings)[keyof typeof RoleSettings];

export type RoleSettingOptions = Record<RoleSettings, boolean>;
