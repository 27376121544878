import { PermissionGroup } from "@/react/types";

const organizationReportPermissions: PermissionGroup = {
  name: "Organization Report",
  key: "organization_reports",
  description: null,
  show: (_organization, _currentUser, _role, props) => !props.reportSetsEnabled,
  permissions: {
    view: {
      name: "View Organization Reports",
      key: "organization_report_permission_view",
      description: null,
    },
    manage: {
      name: "Manage Organization Reports",
      key: "organization_report_permission_manage",
      description: null,
    },
  },
};

export default organizationReportPermissions;
