import React, { useCallback, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import {
  Button,
  Dialog,
  DialogContent,
  HStack,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";

import { getLocalStorageItem, setLocalStorageItem } from "@/lib/local-storage";
import { useIsDesktop, useIsTablet } from "@/react/hooks/breakpoints";
import { useCurrentUser } from "@/react/context/mgmt-api/user";

const WELCOME_MODAL_STORAGE_KEY = "welcomeModalAcknowledged";
const learnMoreLink =
  "https://demos.smartrent.com/pages/44d8acd9-9983-468c-bc7a-8e3affbcdee6";

export const WelcomeModal = () => {
  const currentUser = useCurrentUser();
  const userId = currentUser?.id;
  const [isWelcomeModalVisible, setWelcomeModalVisible] = useState(() => {
    return !getLocalStorageItem(WELCOME_MODAL_STORAGE_KEY, userId);
  });

  const closeWelcomeModal = useCallback(() => {
    setLocalStorageItem(WELCOME_MODAL_STORAGE_KEY, userId, "true");
    setWelcomeModalVisible(false);
  }, [userId]);

  return (
    <Dialog
      visible={isWelcomeModalVisible}
      onClose={closeWelcomeModal}
      titleID={"welcome-modal-title"}
    >
      <WelcomeModalContent onClose={closeWelcomeModal} />
    </Dialog>
  );
};

const WelcomeModalContent = ({ onClose }: { onClose: () => void }) => {
  const { colors } = useTheme();
  const [currentStep, setCurrentStep] = useState(0);
  const maxStepIndex = stepsData.length - 1;
  const incrementStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const handleNext = () => {
    if (currentStep < maxStepIndex) {
      incrementStep();
    }
  };

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = !isDesktop && !isTablet;

  const modalWidth = (() => {
    if (isDesktop) {
      return 900;
    } else if (isTablet) {
      return 760;
    }
  })();

  return (
    <DialogContent
      style={[
        {
          width: modalWidth,
          height: isMobile ? 600 : 460,
        },
        styles.welcomeModal,
        { flexDirection: isMobile ? "column-reverse" : "row" },
      ]}
    >
      <View style={styles.welcomeModalLeft}>
        <StepContent currentStep={currentStep} />
        <HStack align="center" spacing={48}>
          <Button
            size={"large"}
            onPress={currentStep === maxStepIndex ? onClose : handleNext}
          >
            {currentStep === maxStepIndex ? "Get Started" : "Next"}
          </Button>

          <HStack spacing={12}>
            {currentStep === maxStepIndex ? (
              <Button
                variation={"plain"}
                href={learnMoreLink}
                size={"large"}
                target={"blank"}
              >
                Learn more
              </Button>
            ) : (
              <DotIndicators
                onIndicatorPress={setCurrentStep}
                getBackgroundColor={(index) =>
                  index === currentStep ? colors.primary : colors.gray300
                }
              />
            )}
          </HStack>
        </HStack>
      </View>

      <View style={styles.welcomeModalRight}>
        <Image
          style={styles.welcomeModalImage}
          source={{
            uri: stepsData[currentStep].image,
          }}
          resizeMode="cover"
          alt={`Step ${currentStep + 1}`}
        />
      </View>
    </DialogContent>
  );
};

type DotIndicatorsProps = {
  onIndicatorPress: (index: number) => void;
  getBackgroundColor: (index: number) => string;
};

const DotIndicators = ({
  onIndicatorPress,
  getBackgroundColor,
}: DotIndicatorsProps) => {
  return (
    <>
      {stepsData.map((_, index) => (
        <Button
          key={index}
          variation={"plain"}
          onPress={() => onIndicatorPress(index)}
        >
          <View
            style={{
              width: 12,
              height: 12,
              borderRadius: 6,
              backgroundColor: getBackgroundColor(index),
            }}
          />
        </Button>
      ))}
    </>
  );
};

const StepContent = ({ currentStep }: { currentStep: number }) => {
  return (
    <VStack spacing={24} style={styles.textContainer}>
      <Typography variation="bold.title.one">
        {stepsData[currentStep].title}
      </Typography>
      <Typography variation="regular.body.medium">
        {stepsData[currentStep].description.map((segment, index) => (
          <Typography
            key={index}
            variation={"regular.body.medium"}
            style={{ fontWeight: segment.bold ? "bold" : "normal" }}
          >
            {segment.text}
          </Typography>
        ))}
      </Typography>
    </VStack>
  );
};

export interface StepData {
  title: string;
  description: {
    text: string;
    bold?: boolean;
  }[];
  image: string;
}

const stepsData: StepData[] = [
  {
    title: "A New Way to Navigate",
    description: [
      {
        text: "We’ve polished up your navigation experience so it's ready to shine. ",
      },
      { text: "Click below to see what’s new.", bold: true },
    ],
    image: "/images/welcome-modal/right-image__1.jpg",
  },
  {
    title: "Streamlined Sidebar",
    description: [
      {
        text: "We've made it easier to access your properties, organization tools, and more so you can ",
      },
      { text: "find what you need faster.", bold: true },
    ],
    image: "/images/welcome-modal/right-image__2.jpg",
  },
  {
    title: "Turbo Topbar",
    description: [
      {
        text: "We've upgraded your topbar",
        bold: true,
      },
      {
        text: " with better page titles, app-wide search, centralized sync, and quick access to your profile.",
      },
    ],
    image: "/images/welcome-modal/right-image__3.jpg",
  },
  {
    title: "Personalized Profile",
    description: [
      {
        text: "Your personalized profile menu is here! ",
      },
      {
        text: "Jump into your tasks with ease",
        bold: true,
      },
      {
        text: " and discover how the new navigation boosts your workflow!",
      },
    ],
    image: "/images/welcome-modal/right-image__4.jpg",
  },
];

const styles = StyleSheet.create({
  welcomeModal: {
    overflow: "hidden",
    marginHorizontal: 8,
  },
  welcomeModalLeft: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 56,
    justifyContent: "space-between",
  },
  welcomeModalRight: {
    flex: 1,
  },
  welcomeModalImage: {
    width: "100%",
    height: "100%",
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
  },
});
