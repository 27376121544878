import { PermissionGroup } from "@/react/types";

const teamboardPermissions: PermissionGroup = {
  name: "Teamboard",
  key: "teamboard",
  description: null,
  show: (_organization, _currentUser, _role, props) =>
    props.smrtCoreUsersEnabled,
  permissions: {
    view: {
      name: "View Teamboard",
      key: "work_teamboard_permission_view",
      description: null,
    },
  },
};

export default teamboardPermissions;
