import { PermissionGroup } from "@/react/types";

const assetPermissions: PermissionGroup = {
  name: "Assets",
  key: "assets",
  description: null,
  show: (_organization, _currentUser, _role, props) =>
    props.smrtCoreUsersEnabled,
  permissions: {
    view: {
      name: "View Assets",
      key: "work_asset_permission_view",
      description: null,
    },
    update: {
      name: "Modify Assets",
      key: "work_asset_permission_update",
      description: null,
    },
    create: {
      name: "Create Assets",
      key: "work_asset_permission_create",
      description: null,
    },
    manage_types: {
      name: "Manage Asset Types",
      key: "work_asset_permission_manage_types",
      description: null,
    },
    block_make_create: {
      name: "Disable Asset Make Create",
      key: "work_asset_permission_block_make_create",
      description: null,
    },
  },
};

export default assetPermissions;
