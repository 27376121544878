import { PermissionGroup } from "@/react/types";

const taskPermissions: PermissionGroup = {
  name: "Tasks",
  key: "tasks",
  description: null,
  show: (_organization, _currentUser, _role, props) =>
    props.smrtCoreUsersEnabled,
  permissions: {
    assign: {
      name: "Assign Tasks",
      key: "work_task_permission_assign",
      description: null,
    },
    change_status: {
      name: "Manage Task Status",
      key: "work_task_permission_change_status",
      description: null,
    },
    complete: {
      name: "Close Tasks of Others",
      key: "work_task_permission_complete",
      description: null,
    },
    create: {
      name: "Create Tasks",
      key: "work_task_permission_create",
      description: null,
    },
    manage_files: {
      name: "Manage Task Files",
      key: "work_task_permission_manage_files",
      description: null,
    },
    manage_series: {
      name: "Manage Repeating Tasks",
      key: "work_task_permission_manage_series",
      description: null,
    },
    prioritize: {
      name: "Prioritize Tasks",
      key: "work_task_permission_prioritize",
      description: null,
    },
    reassign: {
      name: "Reassign Tasks",
      key: "work_task_permission_reassign",
      description: null,
    },
    reopen_complete: {
      name: "Reopen Tasks",
      key: "work_task_permission_reopen_complete",
      description: null,
    },
    view_assigned: {
      name: "View Tasks Assigned to Self",
      key: "work_task_permission_view_assigned",
      description: null,
    },
    view_other: {
      name: "View Tasks of Others",
      key: "work_task_permission_view_other",
      description: null,
    },
    view_reported: {
      name: "View Tasks Reported by Self",
      key: "work_task_permission_view_reported",
      description: null,
    },
    manage_time_tracking: {
      name: "Manage Time Tracking",
      key: "work_task_permission_manage_time_tracking",
      description: null,
    },
    modify_timeframe: {
      name: "Modify Schedule",
      key: "work_task_permission_modify_timeframe",
      description: null,
    },
    create_bulk_project: {
      name: "Bulk Create Projects",
      key: "work_task_permission_create_bulk_project",
      description: null,
    },
    create_notes: {
      name: "Create Comments",
      key: "work_task_permission_create_notes",
      description: null,
    },
    manage_templates: {
      name: "Manage Templates",
      key: "work_task_permission_manage_templates",
      description: null,
    },
  },
};

export default taskPermissions;
