import { AppState } from "react-native";
import axios, { AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import Debug from "debug";

import { auth } from "@/lib/links";

const debug = Debug("cmw:authentication");

let accessToken: string | null = null;
let accessTokenPromise: Promise<string> | null = null;

export function setAccessToken(token: string) {
  accessToken = token;
}

function calculateMillisecondsUntilExpiration(accessToken: string): number {
  const browserTime = new Date().getTime();
  const jwtExp: number = JSON.parse(atob(accessToken.split(".")[1])).exp;

  const millisecondsUntilExpiration =
    new Date(jwtExp * 1000).getTime() - browserTime;

  return millisecondsUntilExpiration;
}

/**
 * Returns a promise that will resolve to an access token.
 *
 * When the page first loads we won't have a promise yet so
 * we create one and immediatley begin fetching the new token.
 *
 * Once we have a token, the promise will immediately resolve.
 *
 * Shortly before the token expires, we will fetch a new token.
 * But we want to continue using the old token until we have
 * the new one so the promise won't change until the new token
 * is available.
 *
 * Thus, we only really have to wait for a token when the page
 * first loads. All other times a token should be immediately
 * available.
 */
export function getAccessToken() {
  if (!accessTokenPromise) {
    debug("getAccessToken: no accessTokenPromise, creating one");

    accessTokenPromise = new Promise((resolve) => {
      debug("getAccessToken: executing accessTokenPromise");

      fetchAccessToken().then((token) => {
        debug("getAccessToken: fetchAccessToken resolved");

        accessToken = token;
        resolve(token);

        const millisecondsUntilExpiration =
          calculateMillisecondsUntilExpiration(accessToken);
        if (calculateMillisecondsUntilExpiration(accessToken) <= 0) {
          // We just got a new access token, but it's already expired?
          // This can happen when the client's time is incorrect.
          // "Incorrect" meaning that their computer is set to a certain
          // timezone, but the actual time is inconsistent with that timezone.
          debug(
            `getAccessToken: access token expired ${millisecondsUntilExpiration}ms ago`
          );

          window.location.href = auth.login({
            status: "error",
            message:
              "Unable to authenticate your request. Check that your computer's time is consistent with your timezone.",
          });

          return;
        }

        authenticationHeartbeat();
      });
    });
  }

  return accessTokenPromise;
}

/**
 * Fetch a new access token shortly before the current one expires.
 *
 * The logic for handling the promise is a little different than in
 * getAccessToken because the needs are different. getAccessToken is
 * called by other modules. It must always return a promise that will
 * eventually resolve to a token. It also needs to only fetch the token
 * once and cache the promise for future calls.
 *
 * This function is called when we already have a promise that resolves
 * to a token but we want to fetch a new token before the current token
 * expires. So we don't save a new promise until we know it is ready
 * to immediately resolve to a new token.
 */
function authenticationHeartbeat() {
  const nextHeartbeat = calculateHeartbeatInterval();
  debug(`authenticationHeartbeat: next heartbeat in ${nextHeartbeat}ms`);

  setTimeout(() => {
    debug("authenticationHeartbeat: heartbeat starting");
    const newPromise = fetchAccessToken();
    newPromise.then((token) => {
      setAccessToken(token);
      accessTokenPromise = newPromise;
      authenticationHeartbeat();
    });
  }, nextHeartbeat);
}

function fetchAccessToken() {
  debug("fetchAccessToken");
  return axios({
    url: "/authentication/access-token",
    method: "POST",
  })
    .then(({ data: { access_token } }: AxiosResponse) => {
      debug("fetchAccessToken: received access token");
      return access_token;
    })
    .catch((error) => {
      debug("fetchAccessToken: error");
      Sentry.captureException(error);

      // If the API responds with a 401 then it means they're not authenticated
      // so we send them to the login page.
      if (error?.response?.status === 401) {
        window.location.href = auth.login();
      }
    });
}

AppState.addEventListener("change", (state) => {
  if (state === "active" && accessToken) {
    try {
      if (calculateMillisecondsUntilExpiration(accessToken) <= 0) {
        window.location.href = auth.login();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
});

function calculateHeartbeatInterval() {
  debug("calculateHeartbeatInterval");
  let heartbeatInterval = 30000;

  try {
    if (!accessToken) {
      return heartbeatInterval;
    }

    const millisecondsUntilExpiration =
      calculateMillisecondsUntilExpiration(accessToken);
    debug(
      `calculateHeartbeatInterval: millisecondsUntilExpiration: ${millisecondsUntilExpiration}`
    );

    if (millisecondsUntilExpiration <= 0) {
      debug(
        `calculateHeartbeatInterval: access token expired ${millisecondsUntilExpiration}ms ago`
      );
      window.location.href = auth.login();

      return;
    }

    // Subtract 10s to account for potential network latency. This gives us some wiggle
    // room to make sure the token gets refreshed before it actually expires.
    heartbeatInterval = millisecondsUntilExpiration - 10000;

    if (heartbeatInterval <= 0) {
      debug(
        `calculateHeartbeatInterval: heartbeatInterval was reduced to ${heartbeatInterval}ms; setting to 1ms`
      );
      heartbeatInterval = 1;
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  return heartbeatInterval;
}
