import { PermissionGroup } from "@/react/types";

const tagPermissions: PermissionGroup = {
  name: "Tags",
  key: "tags",
  description: null,
  show: (_organization, _currentUser, _role, props) =>
    props.smrtCoreUsersEnabled,
  permissions: {
    view: {
      name: "View Tags",
      key: "work_tag_permission_view",
      description: null,
    },
    manage: {
      name: "Manage Tags",
      key: "work_tag_permission_manage",
      description: null,
    },
    modify: {
      name: "Modify Tags",
      key: "work_tag_permission_modify",
      description: null,
    },
    create: {
      name: "Create Tags",
      key: "work_tag_permission_create",
      description: null,
    },
  },
};

export default tagPermissions;
